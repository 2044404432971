<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }" v-if="tank!==null">
    <!-- 탱크정보 입력폼 --------------------------------------------------------------------------------------------->
    <BRow class="mb-1" v-if="tank">
      <BCol>
        <BRow>
          <BCol sm="6">
            <BInputGroup size="sm" prepend="탱크 ID">
              <BFormInput size="sm"
                          type="text"
                          class="bg-warning"
                          id="tid"
                          :state="isValidExp(tank.tid, 'hex')&&tank.tid.length===4"
                          v-model="tank.tid"
                          maxLength="4"
                          @input="v=>(tank.tid = v.toUpperCase())"
                          required/>
            </BInputGroup>

            <BTooltip target="tid"
                      variant="danger"
                      type="text"
                      :show="!(isValidExp(tank.tid, 'hex')&&tank.tid.length===4)"
                      placement="right" triggers="manual">
              16진수 4자 입력
            </BTooltip>
          </BCol>
          <BCol sm="6">
            <BInputGroup size="sm" prepend="관리그룹">
              <BFormSelect size="sm"
                           type="text"
                           id="tankType"
                           :state="tank.arCode!==''"
                           v-model="tank.arCode"
                           :options="arCodeOpts"
              />
            </BInputGroup>
          </BCol>
        </BRow>

      </BCol>
    </BRow>

    <BRow class="mb-1">
      <BCol>
        <BRow>
          <BCol>
            <BInputGroup size="sm" prepend="탱크이름">
              <BFormInput size="sm" id="tank-name"
                          v-model="tank.name"
                          maxLength="100"
                          :state="(tank.name.length>0)"/>
            </BInputGroup>
            <BTooltip target="tank-name"
                      variant="danger"
                      :show="tank.name.length===0"
                      placement="right" :triggers="['manual']">
              탱크이름 필수 입력
            </BTooltip>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="탱크코드">
              <BFormInput size="sm" id="tank-code"
                          v-model="tank.tankCode"
                          maxLength="100"
                          :state="(tank.tankCode.length>0)"/>
            </BInputGroup>

            <BTooltip target="tank-code"
                      variant="danger"
                      :show="tank.tankCode.length===0"
                      placement="right" :triggers="['manual']">
              탱크코드 ex) 3-J-C(번호-유종-지역)
            </BTooltip>



          </BCol>
        </BRow>
      </BCol>
    </BRow>

    <BRow class="mb-1">
      <BCol>
        <BRow>
          <BCol>
            <BInputGroup size="sm" prepend="탱크타입">
              <BFormSelect size="sm"
                           id="tankType"
                           :state="tank.tankType!==''"
                           v-model="tank.tankType"
                           :options="opts['TANK_TYPE']"
                           required/>
            </BInputGroup>
            <BTooltip target="tankType"
                      variant="danger"
                      :show="tank.tankType===''"
                      placement="right" :triggers="'manual'">
              탱크타입 선택
            </BTooltip>
          </BCol>
          <BCol>
            <BInputGroup prepend="D/F 코드" size="sm">
              <BFormInput size="sm" id="dfCode" type="text"
                          v-model="tank.dfCode"
                          placeholder="Deliis/F 연동 코드"
                          maxLength="40"/>
            </BInputGroup>



          </BCol>
        </BRow>

      </BCol>
    </BRow>

    <BRow class="mb-1">
      <BCol>
        <BRow>
          <BCol>
            <BInputGroup size="sm" prepend="탱크모형" class="mb-1">
              <BFormSelect size="sm"
                           id="tankShape"
                           :state="tank.tankShape!==''"
                           v-model="tank.tankShape"
                           :options="opts['TANK_SHAPE']"
                           required/>
            </BInputGroup>
            <BTooltip target="tankShape"
                      variant="danger"
                      :show="tank.tankShape===''"
                      placement="right" :triggers="['manual']">
              탱크모형 선택
            </BTooltip>

            <BInputGroup size="sm" prepend="사용형태" class="mb-1">
              <BFormSelect size="sm" id="tankUse"
                           v-model="tank.tankUse"
                           :state="tank.tankUse!==''"
                           :options="opts['TANK_USE']"
              />
            </BInputGroup>
            <BTooltip target="tankUse"
                      variant="danger"
                      :show="tank.tankUse===''"
                      placement="right" :triggers="['manual']">
              사용형태 선택
            </BTooltip>

            <BInputGroup prepend="가로(㎜)" size="sm" class="mb-1">
              <BFormInput size="sm" id="tankWidth" type="number"
                          v-model="tank.tankWidth"
                          :state="tank.tankWidth>0"/>
            </BInputGroup>

            <BInputGroup prepend="높이(㎜)" size="sm" class="mb-1">
              <BFormInput size="sm"
                          id="tankHeight" type="number"
                          v-model="tank.tankHeight"
                          :state="tank.tankHeight>0"/>
            </BInputGroup>

            <BInputGroup size="sm" prepend="우선순위">
              <BFormInput size="sm" id="order" number v-model="tank.order"/>
            </BInputGroup>


          </BCol>
          <BCol>
            <BImg height="160"
                  v-show="tank.tankShape!==''"
                  :src="`/img/tank/shape-${tank.tankShape}.png`"/>
          </BCol>
        </BRow>






      </BCol>
    </BRow>


    <BTableSimple class="m-0 mb-2" small>
      <BTr>
        <BTd>

          <BInputGroup prepend="저장유종" size="sm" >
            <BFormSelect size="sm"
                         id="oilCode"
                         :state="tank.oilCode!==''"
                         v-model="tank.oilCode"
                         @input="setOilTempGroup"
                         :options="opts['OIL_CODE']"
            />
          </BInputGroup>

          <BTooltip target="oilCode"
                    variant="danger"
                    :show="tank.oilCode===''"
                    placement="right" triggers="manual">
            유종 선택
          </BTooltip>

        </BTd>
        <BTd>
          <BInputGroup prepend="표시컬러" size="sm">
            <BFormInput id="color" size="sm"
                        @click="v=>{pickerShow=!pickerShow}"
                        type="text"
                        v-model="tank.color"
                        :style="{backgroundColor: tank.color, textShadow:'1px 1px #FFFFFF'}"
            />
          </BInputGroup>
          <BTooltip target="color"
                    variant="danger"
                    :show="tank.color===''"
                    placement="right" triggers="manual">
            탱크 컬러 선택
          </BTooltip>
          <swatches-picker id="color-pick"
                           class="small"
                           v-show="pickerShow"
                           v-model="tank.color"
                           @input="v=>{tank.color=v.hex;pickerShow=false;}"/>

        </BTd>
      </BTr>
      <BTr>
        <BTd colspan="2">
          <BInputGroup size="sm">
            <BInputGroupAppend>
              <BInputGroupText size="sm"> 용량(GL) </BInputGroupText>

              <BFormInput size="sm" id="tankVolume"
                          type="number"
                          v-model="tank.tankVolumeGl"
                          @input="tankVolumeChange"
                          :step="1" :min="1" :max="1000000"
                          :state="tank.tankVolumeGl>0"/>

              <BInputGroupText size="sm" class="text-primary ml-1" style="width: 120px">
                {{ toComma(tank.tankVolume)}} <BBadge variant="info" class="ml-1">L</BBadge>
              </BInputGroupText>

            </BInputGroupAppend>
          </BInputGroup>

          <BTooltip target="tankHeight"
                    variant="danger"
                    :show="!tank.tankHeight"
                    placement="right" :triggers="['manual']">
            탱크높이(㎜) 필수 입력
          </BTooltip>

          <BTooltip target="tankVolume"
                    variant="danger"
                    :show="!tank.tankVolume"
                    placement="right" :triggers="['manual']">
            저장 용량 Gallon 단위 입력
          </BTooltip>
        </BTd>
      </BTr>
      <BTr>
        <BTd>
          <BInputGroup prepend="저장률" size="sm" >
            <BFormInput size="sm" id="realVolRate" type="number"
                        v-model="tank.realVolRate"
                        @input="tankVolumeChange"
                        :step="0.01" :min="0.01" :max="1"
                        :state="tank.realVolRate>0"/>
            <BInputGroupAppend>
              <BInputGroupText size="sm" class="text-info font-weight-bolder" style="min-width: 120px">
                {{ toComma(Math.round(tank.tankRealVol*con.LTR2GL)) }} <BBadge variant="success" class="ml-1">GL</BBadge>
              </BInputGroupText>
            </BInputGroupAppend>
          </BInputGroup>

          <BTooltip target="realVolRate"
                    variant="danger"
                    :show="!tank.realVolRate"
                    placement="right" :triggers="['manual']">
            저장율 95% = 0.95 입력
          </BTooltip>
        </BTd>
        <BTd>
          <BInputGroup size="sm">
            <BInputGroupAppend>
              <BInputGroupText size="sm" class="text-info" style="min-width: 120px">
                {{ toComma(Math.round(tank.tankRealVol)) }} <BBadge variant="info" class="ml-1">L</BBadge>
              </BInputGroupText>
            </BInputGroupAppend>
          </BInputGroup>
        </BTd>
      </BTr>
      <BTr>
        <BTd>
          <BInputGroup prepend="환산재고보정(L)" size="sm">
            <BFormInput size="sm" id="volRevision" type="number"
                        v-model="tank.volRevision"
                        :step="1" :min="1" :max="1000000"/>
          </BInputGroup>
        </BTd>
        <BTd>
          <BInputGroup prepend="재고저장시간(초)" size="sm">
            <BFormInput size="sm" id="saveInterval" type="number"
                        v-model="tank.saveInterval"
                        :step="10" :min="10" :max="6000"/>
          </BInputGroup>
        </BTd>
      </BTr>
      <BTr>
        <BTd>
          <BInputGroup prepend="노즐" size="sm">
            <BFormSelect size="sm"
                         id="nozType"
                         :state="tank.nozType!==''"
                         v-model="tank.nozType"
                         :options="opts['NOZ_TYPE']"
            />
            <BFormSelect size="sm"
                         id="nozSize"
                         :state="tank.nozSize!==''"
                         v-model="tank.nozSize"
                         :options="opts['NOZ_SIZE']"
            />
          </BInputGroup>
        </BTd>
        <BTd>
          <BInputGroup prepend="센서" size="sm">
            <BFormSelect size="sm"
                         id="sensorType"
                         :state="tank.sensorType!==''"
                         v-model="tank.sensorType"
                         :options="opts['SENSOR_TYPE']"
            />
          </BInputGroup>
        </BTd>

      </BTr>
    </BTableSimple>




    <BCard header="경보 임계치 설정" border-variant="info" class="mb-1" no-body>
      <div slot="header">
        <BIconExclamationDiamondFill/> 경보 임계치 설정
        <div class="card-header-actions">
          <BFormCheckbox id="eventEnable" v-model="tank.eventEnabled" switch inline>
            임계치 체크
          </BFormCheckbox>
        </div>
      </div>

      <BTableSimple small>
        <BTr>
          <BTd width="20%" class="text-right"><BBadge style="width:100%">고온경보(℃)</BBadge></BTd>
          <BTd>
            <BFormInput size="sm" id="fire_cs" type="number"
                        v-model="tank.thresholds.fire_cs"
                        :step="0.5" :min="0.5" :max="100"
                        :state="tank.thresholds.fire_cs>0"/>
          </BTd>
        </BTr>
        <BTr>
          <BTd width="20%" class="text-right"><BBadge style="width:100%">수분경보(㎜)</BBadge></BTd>
          <BTd>
            <BFormInput size="sm" id="water_mm" type="number"
                        v-model="tank.thresholds.water_mm"
                        :step="1" :min="1" :max="1000"
                        :state="tank.thresholds.water_mm>0"/>
          </BTd>
        </BTr>
        <BTr>
          <BTd width="20%" class="text-right">
            <BBadge style="width:100%" v-b-tooltip.hover :title="leakMessage">
              누유경보(비율)
            </BBadge>
          </BTd>
          <BTd>
            <BInputGroup size="sm" prepend="누유율">
              <BFormInput size="sm" id="leakRate" type="number"
                          v-model="tank.thresholds.leakRate"
                          @input="leakRateChange"
                          :step="0.0001" :min="0.001" :max="1"
                          :state="tank.thresholds.leakRate>0"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">기준(초)</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput size="sm" id="leakIntSecs" type="number"
                          v-model="tank.thresholds.leakIntSecs"
                          @input="leakRateChange"
                          :step="10" :min="10" :max="2592000"
                          :state="tank.thresholds.leakIntSecs>9"/>
            </BInputGroup>
            <b-toast id="leakHelpToast" title="누유경보 도움말" static auto-hide>
              {{leakMessage}}
            </b-toast>
          </BTd>
        </BTr>
        <BTr>
          <BTd width="20%" class="text-right"><BBadge style="width:100%">넘침경보(비율)</BBadge></BTd>
          <BTd>
            <BInputGroup prepend="첫번째" size="sm">
              <BFormInput size="sm" id="overflowRate1" type="number"
                          v-model="tank.thresholds.overflowRate1"
                          @input="overflowRateChange(1)"
                          :step="0.01" :min="0.01" :max="1"
                          :state="tank.thresholds.overflowRate1>0"/>

              <BInputGroupAppend>
                <BInputGroupText size="sm">두번째</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput size="sm" id="overflowRate2" type="number"
                          v-model="tank.thresholds.overflowRate2"
                          @input="overflowRateChange(2)"
                          :step="0.01" :min="0.01" :max="1"
                          :state="tank.thresholds.overflowRate2>0"/>
            </BInputGroup>
          </BTd>
        </BTr>
        <BTr>
          <BTd width="20%" class="text-right"><BBadge style="width:100%">넘침경보(GL)</BBadge></BTd>
          <BTd>
            <BInputGroup prepend="첫번째" size="sm">
              <BFormInput size="sm" id="overflowLtr1" type="number"
                          @input="overflowVolumeChange(1)"
                          v-model="tank.thresholds.overflowGl1"
                          :step="1" :min="1000" :max="1000000"
                          :state="tank.thresholds.overflowGl1>0"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">두번째</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput size="sm" id="overflowLtr2" type="number"
                          @input="overflowVolumeChange(2)"
                          v-model="tank.thresholds.overflowGl2"
                          :step="1" :min="1000" :max="1000000"
                          :state="tank.thresholds.overflowGl2>0"/>
            </BInputGroup>

            <BRow class="small text-muted text-right mb-0">
              <BCol>
                {{toComma(tank.thresholds.overflowLtr1)}} <BBadge variant="info">L</BBadge>
              </BCol>
              <BCol>
                {{toComma(tank.thresholds.overflowLtr2)}} <BBadge variant="info">L</BBadge>
              </BCol>
            </BRow>

          </BTd>
        </BTr>
        <BTr>
          <BTd width="20%" class="text-right"><BBadge style="width:100%">부족경보(비율)</BBadge></BTd>
          <BTd>
            <BInputGroup prepend="첫번째" size="sm">
              <BFormInput size="sm" id="underflowRate1" type="number"
                          v-model="tank.thresholds.underflowRate1"
                          @input="underflowRateChange(1)"
                          :step="0.01" :min="0.01" :max="1"
                          :state="tank.thresholds.underflowRate1>0"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">두번째</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput size="sm" id="underflowRate2" type="number"
                          v-model="tank.thresholds.underflowRate2"
                          @input="underflowRateChange(2)"
                          :step="0.01" :min="0.01" :max="1"
                          :state="tank.thresholds.underflowRate2>0"/>
            </BInputGroup>
          </BTd>
        </BTr>
        <BTr>
          <BTd width="20%" class="text-right"><BBadge style="width:100%">부족경보(GL)</BBadge></BTd>
          <BTd>
            <BInputGroup prepend="첫번째" size="sm">
              <BFormInput size="sm" id="underflowLtr1" type="number"
                          @input="underflowVolumeChange(1)"
                          v-model="tank.thresholds.underflowGl1"
                          :step="1" :min="1000" :max="1000000"
                          :state="tank.thresholds.underflowGl1>0"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">두번째</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput size="sm" id="underflowLtr2" type="number"
                          @input="underflowVolumeChange(2)"
                          v-model="tank.thresholds.underflowGl2"
                          :step="1" :min="1000" :max="1000000"
                          :state="tank.thresholds.underflowGl2>0"/>
            </BInputGroup>
            <BRow class="small text-muted text-right mb-0">
              <BCol>
                {{toComma(tank.thresholds.underflowLtr1)}} <BBadge variant="info">L</BBadge>
              </BCol>
              <BCol>
                {{toComma(tank.thresholds.underflowLtr2)}} <BBadge variant="info">L</BBadge>
              </BCol>
            </BRow>

          </BTd>
        </BTr>

      </BTableSimple>
    </BCard>


    <BCard header="알람 설정" border-variant="info" class="mb-1" no-body>
      <div slot="header" v-b-toggle.alarm-config>
        <BIconBellFill></BIconBellFill> 알람설정
        <div class="card-header-actions">
          <BFormCheckbox id="alarmEnable" v-model="tank.alarm.enabled" switch inline>
            알람 사용
          </BFormCheckbox>

          <BFormCheckbox class="ml-1" id="display" v-model="tank.display" :value="true" :unchecked-value="false" switch inline>
            <span style="color:red">모니터링 표시</span>
          </BFormCheckbox>
        </div>
      </div>

      <b-collapse id="alarm-config">
      <BRow>
        <BCol class="ml-2">
          <BTableSimple small class="mb-1">
            <BTr>
              <BTd width="10%">
                <BBadge class="w-100" variant="primary">콘솔 알람</BBadge>
              </BTd>
              <BTd class="font-weight-bold small">
                <BFormCheckbox class="ml-3" id="consoleIcon" v-model="tank.alarm.console.icon" :value="true" :unchecked-value="false" switch inline>
                  알림창
                </BFormCheckbox>
                <BFormCheckbox id="consoleSound" v-model="tank.alarm.console.sound" :value="true" :unchecked-value="false" switch inline>
                  사운드
                </BFormCheckbox>
                <BFormCheckbox id="consoleTts" v-model="tank.alarm.console.tts" :value="true" :unchecked-value="false" switch inline>
                  음성
                </BFormCheckbox>

              </BTd>
            </BTr>
            <BTr>
              <BTd>

                <BBadge class="w-100" variant="danger" >경보 알람</BBadge>
              </BTd>
              <BTd class="font-weight-bold small">
                <BRow class="text-nowrap">
                <BCol sm="4" v-for="(e,i) in tank.alarm.events" :key="i">
                  <BFormCheckbox v-model="e.enabled"
                                 class="ml-3"
                                 switch inline>
                    {{ eventMap[e.code] }}
                  </BFormCheckbox>

                </BCol>
                </BRow>

              </BTd>
            </BTr>
            <BTr>
              <BTd>
                <BBadge class="w-100" variant="warning">경광등 알람</BBadge>
              </BTd>
              <BTd class="font-weight-bold small">
                <BFormCheckbox class="ml-3" id="lamp" v-model="tank.alarm.lamp.light" switch inline>
                  램프
                </BFormCheckbox>
                <BFormCheckbox id="lampBuzzer" v-model="tank.alarm.lamp.buzzer" switch inline>
                  부저
                </BFormCheckbox>
              </BTd>
            </BTr>
          </BTableSimple>

        </BCol>
      </BRow>
      </b-collapse>
    </BCard>

    <BCard header="위치정보 설정" border-variant="info" class="mb-1" no-body>
      <div slot="header" v-b-toggle.position-config>
        <BIconPinMapFill></BIconPinMapFill> 위치정보
        <div class="card-header-actions small">
          <BButton class="small" size="sm" variant="info" @click="savePosition">위치정보저장</BButton>
        </div>
      </div>

      <b-collapse id="position-config">
        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="GPS 위치" size="sm">
              <BInputGroupAppend class="ml-2">
                <BInputGroupText size="sm">경도(X)</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankLocationX" size="sm" type="number"
                          v-model="tank.location.coordinates[0]"
                          :state="tank.location.coordinates[0]!==0"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">위도(Y)</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankLocationY" size="sm" type="number"
                          v-model="tank.location.coordinates[1]"
                          :state="tank.location.coordinates[1]!==0"/>
            </BInputGroup>
          </BCol>
        </BRow>
        <BRow>
          <BCol>
            <BInputGroup prepend="Map 위치" size="sm">
              <BInputGroupAppend class="ml-2">
                <BInputGroupText size="sm">X</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionX" size="sm" type="number"
                          v-model="tank.position.x"
                          :state="tank.position.x!==0"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">Y</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionY" size="sm" type="number"
                          v-model="tank.position.y"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">Z</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionZ" size="sm" type="number"
                          v-model="tank.position.z"
                          :state="tank.position.z!==0"/>
            </BInputGroup>
            <BInputGroup prepend="Map 조정" size="sm">
              <BInputGroupAppend class="ml-2">
                <BInputGroupText size="sm">X</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionAX" size="sm" type="number" v-model="tank.position.ax"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">Y</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionAY" size="sm" type="number" v-model="tank.position.ay"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">Z</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionAZ" size="sm" type="number" v-model="tank.position.az"/>
            </BInputGroup>
            <BInputGroup prepend="CAM 조정" size="sm" class="mt-1">
              <BInputGroupAppend class="ml-2">
                <BInputGroupText size="sm">X</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionCX" size="sm" type="number" v-model="tank.position.cx"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">Y</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionCY" size="sm" type="number" v-model="tank.position.cy"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">Z</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionCZ" size="sm" type="number" v-model="tank.position.cz"/>
            </BInputGroup>
            <BInputGroup prepend="라벨 조정" size="sm" class="mt-1">
              <BInputGroupAppend class="ml-2">
                <BInputGroupText size="sm">높이(Y)</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionLBY" size="sm" type="number" v-model="tank.position.ly"/>
              <BInputGroupAppend>
                <BInputGroupText size="sm">표시각(angle)</BInputGroupText>
              </BInputGroupAppend>
              <BFormInput id="tankPositionLBD" size="sm" type="number" v-model="tank.position.lyd"/>

            </BInputGroup>
          </BCol>
        </BRow>
      </b-collapse>
    </BCard>

    <BCard border-variant="info" class="mb-1" no-body>
      <div slot="header">
        <BIconSpeedometer></BIconSpeedometer>표시단위 설정
        <div class="card-header-actions">
          <BInputGroup class="small">
            <BInputGroupAppend>
              <BFormSelect size="sm"
                           prepend="부피 표시 단위"
                           id="unitOfVol"
                           v-model="tank.unitOfVol"
                           :options="con.unitVolOpts"
              />
            </BInputGroupAppend>
            <BInputGroupAppend>
              <BFormSelect size="sm"
                           prepend="길이 표시 단위"
                           id="unitOflen"
                           v-model="tank.unitOfLen"
                           :options="con.unitLenOpts"
              />
            </BInputGroupAppend>
            <BInputGroupAppend>
              <BFormSelect size="sm"
                           prepend="온도 표시 단위"
                           id="unitOfTmp"
                           v-model="tank.unitOfTmp"
                           :options="con.unitTmpOpts"
              />
            </BInputGroupAppend>

          </BInputGroup>

        </div>
      </div>

    </BCard>



    <BRow class="mb-1">
      <BCol>
        <BInputGroup prepend="세척일" size="sm" >
          <BFormInput size="sm" id="cleanDate" type="date" v-model="tank.cleanDate"/>
        </BInputGroup>
      </BCol>
      <BCol>
        <BInputGroup prepend="보수일" size="sm" >
          <BFormInput size="sm" id="maDate" type="date" v-model="tank.maDate"/>
        </BInputGroup>
      </BCol>
    </BRow>

    <BRow class="mb-3">
      <BCol>
        <BBadge pill variant="info">등록</BBadge><span class="text-muted small"> {{`${toLocalTime(tank.createdAt)} | ${tank.regId?tank.regId:'NOBODY'}`}} </span>
      </BCol>
      <BCol class="text-right">
        <BBadge pill variant="primary">수정</BBadge><span class="text-muted small"> {{`${toLocalTime(tank.updatedAt)} | ${tank.updId?tank.updId:'NOBODY'}`}} </span>
      </BCol>
    </BRow>

    <BRow>
      <BCol>
        <b-button class="mt-1"  v-b-toggle.row-tank size="sm">원시 데이터 보기</b-button>
      </BCol>
      <BCol>
        <BButton block variant="danger" @click="saveTank" :disabled="isUpdatingRecord">
          <BIconSave2Fill/> <strong>탱크정보 저장</strong>
          <div class="sk-plane bg-light float-left" style="height:25px;width:25px" v-if="isUpdatingRecord"></div>
          <div class="sk-swing float-right" style="height:25px;width:25px" v-if="isUpdatingRecord" >
            <div class="sk-swing-dot"></div>
            <div class="sk-swing-dot"></div>
          </div>
        </BButton>

      </BCol>
    </BRow>


    <b-collapse id="row-tank" class="mt-2">
      <BTable small class="small" stacked :items="[tank]" :fields="tankFields">
        <template #cell(machine)="data">
          <pre>{{ data.item.machine? data.item.machine._id +' | '+data.item.machine.mid+' | '+data.item.machine.name: '장비없음'}}</pre>
        </template>
        <template #cell(rowData)="data">
          <pre>{{data.item}}</pre>
        </template>
      </BTable>
    </b-collapse>

  </div>

</template>


<style src="spinkit/spinkit.min.css"></style>

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar, setAlarm, sleep,
} from '@/common/utils';
import con from '@/common/constants';

import moment from "moment";
import { Swatches } from 'vue-color'

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankForm",
  components: {
    'swatches-picker': Swatches,
  },
  props: {
    tankId: { type: String, default: '' },
  },

  data () {
    return {
      con,
      leakMessage: '',
      oilTempMap : {},
      maps: this.$store.state.codeMaps,
      opts: this.$store.state.codeOpts,
      machine: null,
      pickerShow: false,
      eventMap: this.$store.state.codeMaps['EVENT'],
      tank: null,
      originTank: null,

      arCodeOpts  : this.$store.state.area['opts'],

      tankFields: [
        { key:'rowData'                  , label: ''},
      ],

      isUpdatingRecord: false,

    }

  },
  async created(){
    console.log("--- TankForm --- created---------------------");
    try{

      await this.getOilTempMap();

      await this.getTankInfo();

    }catch(err){
      console.log(err);
    }
  },
  computed: {},

  mounted() {
    console.log("--- TankForm --- mounted---------------------");
    // below is not work!
  },

  methods: {
    tankVolumeChange(){
      console.log("--- TankForm --- tankVolumeChange");
      const tank = this.tank;
      tank.tankVolume = Math.round(tank.tankVolumeGl * con.GL2LTR );
      tank.tankRealVol = tank.realVolRate * tank.tankVolume;
      tank.thresholds.overflowLtr1 = Math.round(tank.thresholds.overflowRate1 * tank.tankRealVol);
      tank.thresholds.overflowLtr2 = Math.round(tank.thresholds.overflowRate2 * tank.tankRealVol);
      tank.thresholds.underflowLtr1 = Math.round(tank.thresholds.underflowRate1 * tank.tankRealVol);
      tank.thresholds.underflowLtr2 = Math.round(tank.thresholds.underflowRate2 * tank.tankRealVol);

      tank.thresholds.overflowGl1 = Math.round( tank.thresholds.overflowLtr1 * con.LTR2GL );
      tank.thresholds.overflowGl2 = Math.round(tank.thresholds.overflowLtr2 * con.LTR2GL);
      tank.thresholds.underflowGl1 = Math.round(tank.thresholds.underflowLtr1 * con.LTR2GL);
      tank.thresholds.underflowGl2 = Math.round(tank.thresholds.underflowLtr2 * con.LTR2GL);
    },

    leakRateChange(){
      let t = this.tank;
      this.$bvToast.show('leakHelpToast');
      this.leakMessage = `${t.thresholds.leakIntSecs}초 이전 측정치와 현재 측정치 비교하여 ${(t.tankRealVol * t.thresholds.leakRate).toFixed(0)} L 이상 감소한 경우 누유로 설정됩니다`;
    },
    setFromDate(){
      this.toDate =  moment(this.fromDate).add(1,"month").subtract(1,'day').format("YYYY-MM-DD");
    },

    underflowRateChange(step){
      if(step===1){
        this.tank.thresholds.underflowGl1 = Math.round(this.tank.thresholds.underflowRate1 * this.tank.tankRealVol * con.LTR2GL)
        this.tank.thresholds.underflowLtr1 = Math.round(this.tank.thresholds.underflowRate1 * this.tank.tankRealVol )
      }else if(step===2){
        this.tank.thresholds.underflowGl2 = Math.round(this.tank.thresholds.underflowRate2 * this.tank.tankRealVol * con.LTR2GL)
        this.tank.thresholds.underflowLtr2 = Math.round(this.tank.thresholds.underflowRate2 * this.tank.tankRealVol )
      }
    },

    underflowVolumeChange(step){
      console.debug( "underflowVolumeChange", step );
      const {thresholds} = this.tank;
      if(step===1){
        thresholds.underflowRate1 = thresholds.underflowGl1 / (this.tank.tankRealVol * con.LTR2GL);
        thresholds.underflowLtr1 = Math.round( thresholds.underflowGl1 * con.LTR2GL );

      }else if(step===2){
        thresholds.underflowRate2 = thresholds.underflowGl2 / (this.tank.tankRealVol * con.LTR2GL);
        thresholds.underflowLtr2 = Math.round( thresholds.underflowGl2 * con.LTR2GL );
      }
    },

    overflowRateChange(step){
      if(step===1){
        this.tank.thresholds.overflowGl1 = Number((this.tank.thresholds.overflowRate1 * this.tank.tankRealVol * con.LTR2GL).toFixed(3));
        this.tank.thresholds.overflowLtr1 = Math.round(this.tank.thresholds.overflowRate1 * this.tank.tankRealVol );
      }else if(step===2){
        this.tank.thresholds.overflowGl2 = Number( (this.tank.thresholds.overflowRate2 * this.tank.tankRealVol * con.LTR2GL).toFixed(3) );
        this.tank.thresholds.overflowLtr2 = Math.round(this.tank.thresholds.overflowRate2 * this.tank.tankRealVol );
      }
    },

    overflowVolumeChange(step){
      console.debug( "underflowVolumeChange", step );
      const {thresholds} = this.tank;
      if(step===1){
        thresholds.overflowRate1 = Number( (thresholds.overflowGl1 / (this.tank.tankRealVol * con.LTR2GL)).toFixed(4) );
        thresholds.overflowLtr1 = Math.round( thresholds.overflowGl1 * con.LTR2GL );

      }else if(step===2){
        thresholds.overflowRate2 = Number( (thresholds.overflowGl2 / (this.tank.tankRealVol * con.LTR2GL)).toFixed(4) );
        thresholds.overflowLtr2 = Math.round( thresholds.overflowGl2 * con.LTR2GL );
      }
    },



    async getOilTempMap(){
      try{
        this.oilTempMap = {};
        const {result} = await apiCall('get', `/api/oil`);
        if( result.length ){
          for( let r of result ) this.oilTempMap[r.code] = r.oilTempGroup;
        }

      }catch(err){
        console.error("getOilTempMap error", err);
      }

    },

    setOilTempGroup(){
      if( this.tank.oilCode ) this.tank.oilTempGroup = this.oilTempMap[this.tank.oilCode];

      console.log( 'setOilTempGroup --->', this.tank.oilTempGroup );
    },

    async getTankInfo(){
      try{
        console.log("getTankInfo tank._id --------->", this.tankId);
        const rs = await apiCall('get', `/api/tank/${this.tankId}`);
        const tank = rs.result;

        if( tank ) {
          this.setTankThresholds(tank);
        }

        this.originTank = cloneVar(tank);
        this.tank = tank;
        console.log( 'getTankInfo ----------- result --->', tank );

      }catch(err){
        console.error("getTankInfo error", err);
      }
    },

    setTankThresholds(tank){
      tank['tankVolumeGl'] = Math.round(tank.tankVolume * con.LTR2GL);
      tank.thresholds['overflowGl1'] = Math.round(tank.thresholds.overflowLtr1 * con.LTR2GL);
      tank.thresholds['overflowGl2'] = Math.round(tank.thresholds.overflowLtr2 * con.LTR2GL);
      tank.thresholds['underflowGl1'] = Math.round(tank.thresholds.underflowLtr1 * con.LTR2GL);
      tank.thresholds['underflowGl2'] = Math.round(tank.thresholds.underflowLtr2 * con.LTR2GL);
    },
    async savePosition(){
      try{
        const position = this.tank.position;
        const coordinates = this.tank.location.coordinates;
        const param = {
          coordinates,
          position
        }
        const rs = await apiCall("PUT", `/api/tank/position/${this.tank._id}`, param );
        await this.toastResult(rs, `위치정보 수정`);
      }catch(err){
        console.error(err);
      }

    },


    async saveTank(){
      let rs = { code: 999, message: '', result: false };

      try{
        const tank = this.tank;
        if(!tank.eventEnabled){
          if(!await this.confirmModal('임계치 체크가 해제 되어 경보 및 이벤트가 기록 되지 않습니다. 진행 하시겠습니까?')){
            tank.eventEnabled = true;
            return;
          }
        }

        this.isUpdatingRecord = true;
        // const row = this.$refs['excelGrid'].currentRecord;


        const {thresholds} = tank;
        thresholds.overflowLtr1 = Math.round( thresholds.overflowGl1 * con.GL2LTR );
        thresholds.overflowLtr2 = Math.round( thresholds.overflowGl2 * con.GL2LTR );
        thresholds.unferflowLtr1 = Math.round( thresholds.underflowGl1 * con.GL2LTR );
        thresholds.unferflowLtr2 = Math.round( thresholds.underflowGl2 * con.GL2LTR );

        delete tank.machine; // 업데이트 금지 MachineForm 장비연결 버튼으로 처리
        delete tank.mid; // 업데이트 금지  MachineForm 정비연결 버튼으로 처리
        console.log( 'param --->', tank );

        rs = await apiCall("PUT", `/api/tank/${tank._id}`, tank);
        console.log( 'result ----------->', rs );
        this.tank = rs.result;

        this.setTankThresholds(this.tank);

        await setAlarm();

        await sleep(500);
        this.isUpdatingRecord = false;
        await this.toastResult(rs, `탱크정보 수정`);


      }catch(err){
        await this.alertDanger(err.message, rs.code, 'danger');
        console.log( err );
      }
    },

  }
}
</script>
